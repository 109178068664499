.font-face_bold {
  font-family: "league_spartan_bold";
}
.font_face_regular {
  font-family: "league_spartan_regular";
}
.headerLinksContainer {
  align-items: center;
  flex-direction: row;
}
.headerLinks {
  display: none;
  margin-right: 4rem;
}
.homeBackgroundImage {
  justify-content: center;
  min-height: 100vh;
  margin-top: -160px;
  background-image: url("../app/shared/assets/images/keita-home.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.desktop-logo {
  height: 10rem;
}
.headerLinksContainer .mobileMenu {
  display: none;
}
.homeContainer {
  margin-left: 20rem;
  justify-content: center;
  flex-direction: column;
}
.gradientBackground {
  background-image: url("../app/shared/assets/images/keita-about.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutContainer {
  margin: 8rem auto;
  flex-wrap: wrap;
}
.aboutText {
  margin: 1rem 0;
}
.aboutButton {
  margin-top: 2rem;
}
.footerContainerlogo {
  margin: 4rem;
  flex-direction: row;
}
.logoContainer {
  justify-items: center;
  grid-auto-flow: column;
}
.footerContainerlinks {
  margin: 4rem;
  flex-direction: row;
}
.footerContainerlogo > div {
  width: 50%;
}
.footerContainerlinks > div {
  width: 50%;
}
.footerLogo {
  margin: 2rem;
}
.footerInfo {
  justify-items: end;
  margin: 2rem 5rem 1.5rem 0rem;
}
.footerMenu {
  display: none;
  margin: 1.5rem 2rem 1.5rem 0rem;
}
.footerLegal {
  display: none;
  margin: 1.5rem;
}
.linkedInLogo {
  filter: invert(50%) sepia(14%) saturate(2487%) hue-rotate(200deg)
    brightness(95%) contrast(106%);
  width: auto;
  margin-left: 2rem;
  height: 30px;
}
.twitch-icon {
  display: none;
  height: 30px;
  margin-right: 1rem;
}
.infoText{
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .homeContainer {
    margin: 10rem;
  }
  .homeIntro {
    width: 60%;
  }

}

@media screen and (max-width: 959px) {
  .homeContainer {
    margin: 0;
  }
  .homeTitle {
    text-align: left;
    margin: 2rem;
  }
  .homeIntro {
    text-align: left;
    margin-left: 2rem;
  }
  .aboutContainer {
    margin-top: 6rem auto;
  }
  .footerContainerlinks > div:first-child {
    width: 40%;
  }
  .footerContainerlinks > div {
    width: 100%;
  }
  .footerContainerlinks {
    margin: 2rem;
  }
  .footerMenu {
    margin: 1.5rem 2rem 1.5rem 0rem;
  }
  .logoContainer {
    grid-auto-flow: row;
  }
  .footerLogo {
    margin-bottom: 2rem;
  }
  .infoText{
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .headerLinks {
    display: none;
    margin-right: 0;
  }
  .headerLinksContainer {
    align-items: center;
    justify-content: flex-end;
    margin: 2rem;
  }

  .aboutContainer {
    margin: 3rem auto;
  }
  .footerContainerlinks > div,
  .footerContainerlinks > div:first-child {
    width: 100%;
  }
  .footerContainerlogo {
    flex-direction: column-reverse;
    align-items: center;
    margin: 2rem;
  }
  .footerInfo {
    justify-items: center;
    margin: 0;
  }
  .footerContainer > div {
    margin: 2rem;
  }
  .footerContainerlinks {
    margin: 0rem 2rem 4rem 2rem;
    flex-direction: column;
    justify-content: center;
  }
  .footerMenu {
    margin: 0rem 0.5rem;
  }
  .mobile {
    margin-bottom: 2rem;
  }
  .footerContainerlinks > div {
    justify-content: center;
  }
  .twitch-icon {
    display: none;
  }
  .headerLinksContainer .mobileMenu {
    display: none;
    /* display: block; */
  }
  .linkedInLogo {
    height: 20px;
  }
  .infoText {
    margin: 2rem;
  }
  .footerLogo {
    margin: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .homeTitle{
    margin-top: 10rem;
  }
}